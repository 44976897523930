import { useScrollOffset } from '@graphcommerce/framer-next-pages'
import { useCustomerValidateToken } from '@graphcommerce/magento-customer/hooks/useCustomerValidateToken'
import { LayoutProvider } from '@graphcommerce/next-ui/Layout/components/LayoutProvider'
import { useTransform, useScroll } from 'framer-motion'
import { useMergeCustomerCart } from '../../hooks/Cart/useMergeCustomerCart'

export type LayoutDefaultProps = {
  header: React.ReactNode
  footer: React.ReactNode
  children?: React.ReactNode
  mainClassName?: string
}

export function LayoutDefault(props: LayoutDefaultProps) {
  const { footer, header, children, mainClassName } = props

  const scrollWithOffset = useTransform(
    [useScroll().scrollY, useScrollOffset()],
    ([y, offset]: number[]) => y + offset,
  )
  useMergeCustomerCart()

  useCustomerValidateToken()

  return (
    <LayoutProvider scroll={scrollWithOffset}>
      <div className='flex min-h-[100vh] flex-col justify-between'>
        {header}
        <main className={`${mainClassName} bg-lightest-gray`}>{children}</main>
        {footer}
      </div>
    </LayoutProvider>
  )
}
